:deep(.breadcrumb) {
  border-bottom: 0;
}

.title_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.6rem;
  border-bottom: 0.01rem solid #e5e5e5;
}

.title_wrap h1 {
  display: flex;
  align-items: center;
  font-size: 0.2rem;
  color: #333333;
  font-weight: bold;
}

.title_wrap h1 .svg-icon {
  display: block;
  margin-right: 0.1rem;
}

.title_wrap .btn_wrap {
  display: flex;
  align-items: center;
}

.title_wrap .btn_wrap button {
  min-width: 1.2rem;
  line-height: 0.4rem;
  font-size: 0.18rem;
  border-radius: 0.03rem;
  margin-left: 0.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: .3s;
}

.title_wrap .btn_wrap button:hover {
  background-color: #f49133;
  color: #fff;
  box-shadow: 0 0 0.1rem 0 #f49133;
}

.title_wrap .btn_wrap .orange {
  background-color: #f49133;
  color: #fff;
}

.form {
  padding-top: 0.4rem;
  padding-bottom: 0.7rem;
}

.form .red {
  color: red;
}

.form .form-item {
  display: flex;
}

.form .form-item .label {
  min-width: 1.4rem;
  font-size: 0.2rem;
  color: #000;
  line-height: 0.6rem;
  font-weight: bold;
  flex-shrink: 0;
}

.form .form-item .input {
  width: 100%;
}

.form .form-item .input.w50 {
  width: 7rem;
}

.form .form-item .input :deep(.ant-select-search) {
  line-height: 0.58rem;
}

.form :deep(.ant-input) {
  border-color: transparent;
  height: 0.58rem;
  line-height: 0.58rem;
  background-color: #f5f5f5;
  width: 100%;
  padding: 0 0.2rem;
  font-size: 0.16rem;
  color: #888888;
}

.form :deep(.ant-input)::placeholder {
  color: #bbbbbb;
}

.form :deep(.ant-select-selection) {
  max-height: 1.26rem;
  overflow-y: auto;
  height: auto;
}

.form :deep(.ant-select-selection__rendered) {
  height: 0.58rem;
  line-height: 0.58rem;
  color: #888888;
  margin: 0 0.1rem;
}

.form :deep(.ant-select-selection__rendered) .ant-select-selection__choice {
  padding-right: 0.3rem;
}

.form :deep(.ant-select-selection__choice__remove) {
  margin-left: 0.15rem;
}

.form :deep(.ant-select-selection__choice) {
  height: 0.5rem;
  line-height: 0.5rem;
  padding: 0 0.15rem;
  border: 0;
  border-radius: 0.03rem;
  background-color: #fff;
}

.form :deep(.ant-select-selection__choice) .anticon-close {
  width: 0.15rem;
  height: 0.15rem;
  border-radius: 50%;
  background-color: #d5d5d5;
  font-size: 0.08rem;
  color: #fff;
}

.form .switch {
  margin-left: 0.2rem;
  height: 0.6rem;
  display: flex;
  align-items: center;
}

.form :deep(.ant-switch) {
  background-color: #eee;
}

.form :deep(.ant-switch):after {
  width: 0.3rem;
  height: 0.3rem;
  background-color: #bbb;
  margin-left: -0.1rem;
  margin-top: -0.06rem;
}

.form :deep(.ant-switch-checked) {
  background-color: #fcdec1;
}

.form :deep(.ant-switch-checked):after {
  margin-left: 0.05rem;
  background-color: #f49133;
}

.form :deep(.ant-form-explain) {
  padding-left: 1.4rem;
}

.form .content :deep(.ant-form-explain) {
  padding-left: 0;
}

.page {
  padding: 0.1rem;
}

.page /deep/ .ant-pagination-simple-pager {
  display: none;
}

.page :deep(.ant-pagination-jump-next),
.page :deep(.ant-pagination-jump-prev),
.page :deep(.ant-pagination-item),
.page :deep(.ant-pagination-prev),
.page :deep(.ant-pagination-next) {
  width: 0.3rem;
  height: 0.3rem !important;
  line-height: 0.3rem !important;
  margin-left: 0.1rem;
  margin-right: 0 !important;
}